main {
  display: flex;
  justify-content: center;
  height: 100vh;

  @media (--landscape) {
    align-items: center;
  }
}

img {
  height: auto;
  max-width: 100%;
  border-radius: 10px;
}

:global(.anchor) {
  display: block;
  text-align: center;
}

:global(.landscape) {
  display: none;

  @media (--landscape) {
    display: block;
  }
}

:global(.portrait) {
  display: none;

  @media (--portrait) {
    display: block;
  }
}
